import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import GalleryOne from "../elements/gallery/GalleryOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import { Accordion, Card, Button } from 'react-bootstrap';

const PopupDataAGM = [
    
    {
        image: "./images/timeline/img1.jpg",
        popupLink: [
            "./images/timeline/img1.jpg",
        ],
    },
    {
        image: "./images/timeline/img2.jpg",
        popupLink: [
            "./images/timeline/img2.jpg",
        ],
    },
    {
        image: "./images/timeline/img3.jpg",
        popupLink: [
            "./images/timeline/img3.jpg",
        ],
    },
    {
        image: "./images/timeline/img4.jpg",
        popupLink: [
            "./images/timeline/img4.jpg",
        ],
    },
    {
        image: "./images/timeline/img5.jpg",
        popupLink: [
            "./images/timeline/img5.jpg",
        ],
    },
  
    {
        image: "./images/timeline/img7.jpg",
        popupLink: [
            "./images/timeline/img7.jpg",
        ],
    },
    {
        image: "./images/timeline/img8.jpg",
        popupLink: [
            "./images/timeline/img8.jpg",
        ],
    },
]
const EventsPage = () => {
    return (
        <>
            <SEO title="Events" />
            <Layout>
                <BreadcrumbOne 
                    title="Events"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Events"
                />
             <div className="main-content">
            <div className="about-area about-style-4 rn-section-gap">
            <div className="container">
                <div className="row row--40 align-items-center">
                <Accordion className={`rn-accordion-style`}>
                <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                     <SectionTitle
                            textAlign = "text-center"
                            radiusRounded = ""
                            subtitle = "AGM 24-26"
                            description = ""
                        />
                    </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                                <Card.Body>
                    <div className="col-lg-12 mt_md--40 mt_sm--40">
                        <div className="content">
                        <div className="row mt_dec--30 row--15">
                      
                                {PopupDataAGM.map((item, index) => (
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt--30" key={index}>
                                        <GalleryOne galleryItem={item} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    </Card.Body>
                    </Accordion.Collapse>
                    </Card>

                    </Accordion>

                </div>
            </div>
        </div>
        </div>
        </Layout>
        </>
    )
}
export default EventsPage;